// src/pages/About.js
import React from 'react';
import './About.css';

function About() {
  return (
    <div className="about-container">
      
      <p>
        Gigabout Australia is a platform to bring fans, music artists, and venues together.
        The platform is currently under development. Please sign up and we will send you an
        email as soon as the site is operational.
      </p>
    </div>
  );
}

export default About;
