// src/components/ArtistPostForm.js
import React, { useState } from 'react';
import { useMutation, gql } from '@apollo/client';
import './ArtistPostForm.css';

const CREATE_ARTIST_POST = gql`
  mutation CreateArtistPost($input: ArtistPostInput!) {
    CreateArtistPost(input: $input) {
      id
      content
      urlPreview
      images
      artistPageId
      ownerId
    }
  }
`;

const ArtistPostForm = ({ artistPageId }) => {
  const [content, setContent] = useState('');
  const [imageFile, setImageFile] = useState(null);
  const [facebookShare, setFacebookShare] = useState(false);
  const [error, setError] = useState(null);

  const token = sessionStorage.getItem('token');

  const [createPost, { loading }] = useMutation(CREATE_ARTIST_POST, {
    context: {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    },
    onCompleted: () => {
      setContent('');
      setImageFile(null);
      setFacebookShare(false);
      setError(null);
    },
    onError: (err) => {
      setError(err.message);
    },
  });

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!artistPageId) return setError('Artist page ID missing');

    const input = {
      content,
      artistPageId: parseInt(artistPageId),
      postToFacebook: facebookShare,
    };

    if (imageFile) {
      input.imageFile = imageFile;
    }

    try {
      await createPost({ variables: { input } });
    } catch (err) {
      console.error('Failed to create post:', err);
    }
  };

  return (
    <div>The post to Facebook option isn't available yet
    <div className="post-form-container">
      <form className="post-form" onSubmit={handleSubmit}>
        <textarea
          placeholder="What's on your mind?"
          value={content}
          onChange={(e) => setContent(e.target.value)}
        />

        <div className="form-actions">
          <label className="upload-label">
            📸
            <input
              type="file"
              accept="image/*"
              onChange={(e) => setImageFile(e.target.files[0])}
            />
          </label>

          <label className="checkbox">
            <input
              type="checkbox"
              checked={facebookShare}
              onChange={(e) => setFacebookShare(e.target.checked)}
            />
            Post to Facebook isn not yet available
          </label>

          <button type="submit" disabled={loading}>
            {loading ? 'Posting...' : 'Post'}
          </button>
        </div>

        {error && <div className="post-error">{error}</div>}
      </form>
    </div>
    </div>
  );
};

export default ArtistPostForm;