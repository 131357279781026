import React from 'react';

function VenuesDirectory(props) {
    return (
        <div style={{ padding: '20px', color: 'white' }}>
           <h1>Venues Directory coming soon.</h1> 
        </div>
    );
}

export default VenuesDirectory;