// components/MerchandiseDashboard.js

import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { useQuery, useMutation, gql } from '@apollo/client';
import MerchandiseItem from '../components/MerchandiseItem';
import MerchandiseForm from './MerchandiseForm';

const GET_MERCH_PAGE = gql`
  query GetMerchPage($input: MerchInput!) {
    getMerchPage(input: $input) {
      id
      title
      description
      ownerId
      items {
        id
        title
        description
        price
        attributes
        mainImage
        merchandisePageId
        categoryName
      }
    }
  }
`;

const DELETE_MERCH_ITEM = gql`
  mutation DeleteMerchItem($id: ID!) {
    deleteMerchItem(id: $id) {
      id
    }
  }
`;

const MerchandiseDashboard = () => {
  const { merchPageId } = useParams();
  const token = sessionStorage.getItem('token');

  const { loading, error, data, refetch } = useQuery(GET_MERCH_PAGE, {
    variables: { input: { id: merchPageId } },
    context: { headers: { Authorization: `Bearer ${token}` } },
  });

  const [deleteMerchItem] = useMutation(DELETE_MERCH_ITEM);

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error: {error.message}</p>;

  const { title, description, items } = data.getMerchPage;

  // 🟢 Group items by category
  const groupedItems = items.reduce((acc, item) => {
    const category = item.categoryName || "Uncategorized";
    if (!acc[category]) acc[category] = [];
    acc[category].push(item);
    return acc;
  }, {});

  const handleDelete = async (id) => {
    await deleteMerchItem({ variables: { id } });
    refetch();
  };

  return (
    <div style={{ position: 'relative', minHeight: '100vh' }}>
      <div style={{ position: 'relative', zIndex: 2 }}>
        <h1 style={{color: 'white'}}>{title}</h1>
        <p style={{color: 'white'}} >{description}</p>

        
        <MerchandiseForm merchPageId={merchPageId} refetch={refetch} />


        <h2 style={{color: 'white'}}>Manage Products</h2>

        <div style={{ paddingLeft: 20, paddingRight: 20 }}>
          {Object.keys(groupedItems).map((category) => (
            <div key={category} style={{ marginBottom: '30px' }}>
              <h2 style={{ textAlign: 'left', color: '#ff4500' }}>{category}</h2>
              <div
                style={{
                  display: 'grid',
                  gridTemplateColumns: 'repeat(auto-fit, minmax(200px, 1fr))',
                  gap: '16px',
                }}
              >
                {groupedItems[category].map((item) => (
                  <div key={item.id}>
                    <MerchandiseItem item={item} />
                    <button onClick={() => handleDelete(item.id)}>Delete</button>
                  </div>
                ))}
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default MerchandiseDashboard;
