// components/EditableProfilePicture.js
import React, { useRef } from 'react';
import { useMutation, gql } from '@apollo/client';
import ProfilePicture from './ProfilePicture';

const UPLOAD_PROFILE_IMAGE = gql`
  mutation UploadArtistProfileImage($artistPageId: ID!, $file: Upload!) {
    UploadArtistProfileImage(artistPageId: $artistPageId, file: $file) {
      id
      profileImage
    }
  }
`;

const EditableProfilePicture = ({ artistPageId, imageUrl, refetch }) => {
  const fileInputRef = useRef(null);
  const token = sessionStorage.getItem('token');

  const [uploadProfileImage, { loading }] = useMutation(UPLOAD_PROFILE_IMAGE, {
    context: { headers: { Authorization: `Bearer ${token}` } },
    onCompleted: () => refetch(),
    onError: (err) => alert(`Error: ${err.message}`),
  });

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      uploadProfileImage({ variables: { artistPageId, file } });
    }
  };

  return (
    <div>
      
      <ProfilePicture profileImage={imageUrl} />
      <button
        onClick={() => fileInputRef.current.click()}
        disabled={loading}
        style={{ marginTop: '10px', cursor: 'pointer' }}
      >
        {loading ? 'Uploading...' : 'Change Profile Picture'}
      </button>
      <input
        ref={fileInputRef}
        type="file"
        accept="image/*"
        style={{ display: 'none' }}
        onChange={handleFileChange}
      />
    </div>
  );
};

export default EditableProfilePicture;
