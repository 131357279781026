// src/components/PublicMerchandiseItem.js
import React from 'react';
import { useMutation, gql } from '@apollo/client';
import { useNavigate, useLocation } from 'react-router-dom';

const ADD_TO_CART = gql`
  mutation AddToCart($merchandiseItemId: ID!, $quantity: Int!) {
    AddToCart(merchandiseItemId: $merchandiseItemId, quantity: $quantity) {
      id
      quantity
    }
  }
`;

const PublicMerchandiseItem = ({ item }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const token = sessionStorage.getItem('token');

  const [addToCart, { loading, error }] = useMutation(ADD_TO_CART, {
    context: {
      headers: { Authorization: `Bearer ${token}` },
    },
    onCompleted: () => {
      alert('✅ Added to cart!');
    },
    onError: (err) => {
      if (err.message === 'Not Authenticated.') {
        // Redirect to login and save current path
        navigate('/login', { state: { from: location.pathname } });
      } else {
        alert('❌ Failed to add item: ' + err.message);
      }
    },
  });

  const handleAddToCart = () => {
    addToCart({ variables: { merchandiseItemId: item.id, quantity: 1 } });
  };

  return (
    <div className="merchandise-item" style={{ border: '3px solid #ccc', padding: '16px', margin: '16px 0', borderRadius: '8px', color: 'white' }}>
      <h3>{item.title}</h3>
      {item.mainImage && (
        <img
          src={item.mainImage}
          alt={item.title}
          style={{ width: '40%', maxHeight: '800px', objectFit: 'contain', margin: '0 auto', display: 'block', backgroundColor: 'black' }}
        />
      )}
      <p>{item.description}</p>
      <p><strong>Price:</strong> ${item.price}</p>
      <button
        onClick={handleAddToCart}
        disabled={loading}
        style={{ marginTop: '10px', padding: '10px 20px', fontSize: '16px', backgroundColor: '#ff4500', color: 'white', border: 'none', borderRadius: '4px' }}
      >
        {loading ? 'Adding...' : 'Add to Cart'}
      </button>
      {error && error.message !== 'Not Authenticated.' && (
        <p style={{ color: 'red' }}>Error: {error.message}</p>
      )}
    </div>
  );
};

export default PublicMerchandiseItem;
