import React from 'react';
import './RoleSelector.css';

const RoleSelector = () => {
  return (
    <div className="role-selector">
      <h4 className="whitetext">Select Artist, Venue or Fan</h4>
      
      <div className="role-links">
        <a href="/artist" className="role-link">🎤 Artist</a>
        <a href="/venue" className="role-link">🏛️ Venue</a>
        <a href="/fan" className="role-link">🙋 Fan</a>
      </div>

      <div className="image-link-wrapper">
        <img
          src="/assets/venueartistfans.webp"
          alt="Venue, Artist, Fan"
          className="responsive-map-image"
        />
      </div>
    </div>
  );
};

export default RoleSelector;
