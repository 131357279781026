// src/pages/ArtistDirectory.js
// src/pages/ArtistDirectory.js
import React, { useEffect, useState, useRef, useCallback } from 'react';
import { gql, useQuery } from '@apollo/client';
import { Link } from 'react-router-dom';
import ProfilePicture from '../components/ProfilePicture';

const GET_ARTISTS = gql`
  query GetArtists {
    allArtistPages {
      id
      title
      description
      profileImage
    }
  }
`;

const ArtistDirectory = () => {
  const { loading, error, data, fetchMore } = useQuery(GET_ARTISTS);
  const [displayedArtists, setDisplayedArtists] = useState([]);
  const [visibleCount, setVisibleCount] = useState(10);
  const observer = useRef();

  const loadMore = useCallback(() => {
    if (data?.allArtistPages) {
      setVisibleCount((prev) => prev + 10);
    }
  }, [data]);

  const lastArtistRef = useCallback(
    (node) => {
      if (loading) return;
      if (observer.current) observer.current.disconnect();

      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting) {
          loadMore();
        }
      });

      if (node) observer.current.observe(node);
    },
    [loading, loadMore]
  );

  useEffect(() => {
    if (data?.allArtistPages) {
      setDisplayedArtists(data.allArtistPages.slice(0, visibleCount));
    }
  }, [data, visibleCount]);

  if (loading) return <p>Loading artists...</p>;
  if (error) return <p>Error loading artists: {error.message}</p>;

  return (
    <div style={{ padding: '2rem' }}>
      <h1 style={{ color: 'white' }}>Artist Directory</h1>
      <div style={{ display: 'grid', gap: '1rem' }}>
        {displayedArtists.map((artist, index) => {
          const isLast = index === displayedArtists.length - 1;
          return (
            <div
              ref={isLast ? lastArtistRef : null}
              key={artist.id}
              style={{
                backgroundColor: '#1e1e1e',
                padding: '1rem',
                borderRadius: '10px',
                color: 'white',
                border: '1px solid #444',
              }}
            >
              <Link to={`/artist/${artist.id}`} style={{ textDecoration: 'none', color: '#61dafb' }}>
                <ProfilePicture profileImage={artist.profileImage}/>
                <h3>{artist.title}</h3>
              </Link>
              <p>{artist.description}</p>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default ArtistDirectory;
