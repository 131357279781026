import React from 'react';
import './Home.css';
import About from './About';
import LoginButton from '../components/LoginButton';
import SignUp from './SignUp';
import MerchandiseListPage from './MerchandiseListPage';
import RegisterButton from '../components/RegisterButton';
import Newsfeed from '../components/NewsFeed';
import Register from './Register';
import RoleSelector from '../components/RoleSelector';


function Home() {
  return (
   
        <> 
      <RegisterButton/>
      <RoleSelector/>
      <About/>
      <Newsfeed/>
        
      </>
      

    
  );
}

export default Home;

