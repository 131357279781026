

import React from 'react'


export default function FansDirectory() {
  return (
    <div style={{ padding: '20px', color: 'white' }}>
        <h1>
        Fans section under construction. coming soon
        </h1>
        </div>
        

  )
}
