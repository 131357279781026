// src/pages/MerchandiseListPage.js
import React from 'react';
import { useQuery, gql } from '@apollo/client';
import { Link } from 'react-router-dom';

const ALL_MERCH_PAGES_QUERY = gql`
  query AllMerchPages {
    allMerchPages {
      id
      title
      description
    }
  }
`;

const MerchandiseListPage = () => {
  const { loading, error, data } = useQuery(ALL_MERCH_PAGES_QUERY);

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error: {error.message}</p>;

  return (
    <div>
      <h1 style={{color: 'white'}}>Merchandise Pages</h1>
      <p style={{color: 'white'}}>This is currently under devolopent and will be available real soon.</p>
      <ul style={{ listStyleType: 'none', padding: 0 }}>
        {data.allMerchPages.map((page) => (
          <li key={page.id} style={{ border: '1px solid #ccc', padding: '10px', marginBottom: '10px', color: 'white'}}>
            <h3>{page.title}</h3>
            <p>{page.description}</p>
            <Link to={`/merchandise/${page.id}`}>View Details</Link>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default MerchandiseListPage;
