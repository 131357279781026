// components/MerchandiseForm.js
import React, { useState } from "react";
import { useMutation, useQuery, gql } from "@apollo/client";
import "../styles.css"; // Ensure styles are included

const GET_CATEGORIES = gql`
  query GetCategories($merchandisePageId: ID!) {
    GetCategories(merchandisePageId: $merchandisePageId) {
      id
      name
    }
  }
`;

const CREATE_MERCH_ITEM = gql`
  mutation CreateMerchandiseItem($input: MerchandiseItemInput!) {
    CreateMerchandiseItem(input: $input) {
      id
      merchandisePageId
      title
      description
      price
      category {
        id
        name
      }
      hasVariants
      variants {
        id
        optionName
        optionValue
        priceAdjustment
        stockQuantity
      }
    }
  }
`;

const MerchandiseForm = ({ merchPageId, refetch }) => {
  const merchandisePageId = merchPageId;
  const token = sessionStorage.getItem("token");

  const [newItem, setNewItem] = useState({
    title: "",
    description: "",
    longDescription: "",
    price: "",
    hasVariants: false,
    variants: [],
    attributes: {},
  });

  const [categoryName, setCategoryName] = useState(""); // Selected or new category
  const [mainImageFile, setMainImageFile] = useState(null);
  const [variants, setVariants] = useState([]);
  const [errorMessage, setErrorMessage] = useState(""); // Store error messages

  const { data, loading, error } = useQuery(GET_CATEGORIES, {
    variables: { merchandisePageId },
  });

  const [createItem, { loading: mutationLoading }] = useMutation(CREATE_MERCH_ITEM, {
    context: {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    },
    onCompleted: () => {
      refetch(); // ✅ Add this line
      setNewItem({
        title: "",
        description: "",
        longDescription: "",
        price: "",
        hasVariants: false,
        variants: [],
        attributes: {},
      });
      setCategoryName("");
      setMainImageFile(null);
      setVariants([]);
      setErrorMessage("");
    },
  });

  // Handle file selection
  const handleImageChange = (e) => {
    setMainImageFile(e.target.files[0]);
  };

  // Handle variant input changes
  const handleVariantChange = (index, field, value) => {
    const updatedVariants = [...variants];
    updatedVariants[index][field] = value;
    setVariants(updatedVariants);
  };

  // Add a new variant row
  const addVariant = () => {
    setVariants([
      ...variants,
      { optionName: "", optionValue: "", priceAdjustment: 0, stockQuantity: 0 },
    ]);
  };

  // Remove a variant row
  const removeVariant = (index) => {
    setVariants(variants.filter((_, i) => i !== index));
  };

  // Handle form submission
  const handleSubmit = async () => {
    if (!token) {
      setErrorMessage("You must be logged in to create an item.");
      return;
    }

    if (!merchandisePageId) {
      setErrorMessage("Error: No merchandisePageId found.");
      return;
    }

    if (!newItem.title || !newItem.price) {
      setErrorMessage("Title and price are required.");
      return;
    }

    setErrorMessage(""); // Clear any previous errors

    const input = {
      ...newItem,
      merchandisePageId: parseInt(merchandisePageId, 10),
      price: parseFloat(newItem.price),
      categoryName,
      mainImageFile,
      variants: newItem.hasVariants
        ? variants.map((variant) => ({
            optionName: variant.optionName,
            optionValue: variant.optionValue,
            priceAdjustment: parseFloat(variant.priceAdjustment),
            stockQuantity: parseInt(variant.stockQuantity, 10),
          }))
        : [],
    };

    try {
      const { data } = await createItem({ variables: { input } });
      console.log("Item Created:", data);
    } catch (error) {
      console.error("Error creating item:", error);
      setErrorMessage(error.message || "An error occurred while creating the item.");
    }
  };

  return (
    <div className="merchandise-form">
      <h2>Create Merchandise Item</h2>

      <input
        type="text"
        placeholder="Title"
        value={newItem.title}
        onChange={(e) => setNewItem({ ...newItem, title: e.target.value })}
        className="form-input"
      />

      <textarea
        placeholder="Description"
        value={newItem.description}
        onChange={(e) => setNewItem({ ...newItem, description: e.target.value })}
        className="form-textarea"
      />

      <textarea
        placeholder="Long Description"
        value={newItem.longDescription}
        onChange={(e) => setNewItem({ ...newItem, longDescription: e.target.value })}
        className="form-textarea"
      />

      {/* Category Dropdown */}
      <label className="form-label">Category:</label>
      <select
        value={categoryName}
        onChange={(e) => setCategoryName(e.target.value)}
        className="form-select"
      >
        <option value="">Select a Category</option>
        {loading ? (
          <option>Loading...</option>
        ) : error ? (
          <option>Error Loading Categories</option>
        ) : (
          data?.GetCategories.map((category) => (
            <option key={category.id} value={category.name}>
              {category.name}
            </option>
          ))
        )}
      </select>

      {/* Add New Category */}
      <input
        type="text"
        placeholder="Or Enter New Category"
        value={categoryName}
        onChange={(e) => setCategoryName(e.target.value)}
        className="form-input"
      />

      {/* Variants Checkbox */}
      <label className="form-checkbox">
        <input
          type="checkbox"
          checked={newItem.hasVariants}
          onChange={(e) => setNewItem({ ...newItem, hasVariants: e.target.checked })}
        />
        This item has variants (e.g., sizes, colors)
      </label>

      {/* Price Input */}
      <input
        type="number"
        placeholder="Base Price"
        value={newItem.price}
        onChange={(e) => setNewItem({ ...newItem, price: e.target.value })}
        className="form-input"
      />

      {/* Image Upload */}
      <input type="file" accept="image/png, image/jpeg" onChange={handleImageChange} className="form-file" />

      {/* Submit Button */}
      <button onClick={handleSubmit} disabled={mutationLoading} className="form-button">
        {mutationLoading ? "Creating..." : "Create Item"}
      </button>

      {/* Error Message Display */}
      {errorMessage && <p className="error-message">{errorMessage}</p>}
    </div>
  );
};

export default MerchandiseForm;
