import React from 'react';
import { useQuery, gql } from '@apollo/client';
import './ArtistPostList.css';

const GET_ARTIST_POSTS = gql`
  query GetArtistPosts($input: ArtistPostsInput!) {
    GetArtistPosts(input: $input) {
      id
      content
      urlPreview
      images
      artistPageId
      facebookPostedId
      ownerId
      createdAt
      updatedAt
    }
  }
`;

const ArtistPostList = ({ artistPageId }) => {
  const { loading, error, data } = useQuery(GET_ARTIST_POSTS, {
    variables: { input: { artistPageId} }
,
  });

  if (loading) return <p>Loading posts...</p>;
  if (error) return <p>Error loading posts: {error.message}</p>;

  const posts = data.GetArtistPosts;

  return (
    <div className="artist-post-list">
      {posts.length === 0 ? (
        <p>No posts yet.</p>
      ) : (
        posts.map((post) => (
          <div className="post-card" key={post.id}>
            <p className="post-date">
              
            
            <strong>Posted:</strong> 
            {post.createdAt 
    ? new Date(Number(post.createdAt)).toLocaleString() 
    : "Unknown Date"}
</p>

            <p className="post-content">{post.content}</p>

            {post.images && post.images.length > 0 && (
              <div className="post-images">
                {post.images.map((url, index) => (
                  <img key={index} src={url} alt={`Post image ${index + 1}`} />
                ))}
              </div>
            )}

            {post.urlPreview && (
              <div className="url-preview">
                {post.urlPreview.image && (
                  <img
                    src={post.urlPreview.image}
                    alt="Preview"
                    className="preview-image"
                  />
                )}
                <div className="preview-info">
                  <strong>{post.urlPreview.title}</strong>
                  <p>{post.urlPreview.description}</p>
                  <a href={post.urlPreview.url} target="_blank" rel="noopener noreferrer">
                    {post.urlPreview.url}
                  </a>
                </div>
              </div>
            )}
          </div>
        ))
      )}
    </div>
  );
};

export default ArtistPostList;
