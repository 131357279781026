import React, { useState, useEffect } from "react";
import { BrowserRouter as Router, Route, Routes, Link } from "react-router-dom";
import { ApolloProvider, useApolloClient } from "@apollo/client";
import client from "./ApolloClient";
import MerchandiseListPage from "./pages/MerchandiseListPage";
import PublicMerchandiseDetailPage from "./pages/PublicMerchandiseDetailPage";
import Home from "./pages/Home";
import Login from "./pages/Login";
import Register from "./pages/Register";
import UserDashboard from "./pages/UserDashboard";
import ArtistDashboard from "./components/ArtistDashboard";
import MerchandiseDashboard from "./components/MerchandiseDashboard";
import RegisterButton from "./components/RegisterButton";
import Newsfeed from "./components/NewsFeed";
import ArtistDirectory from "./pages/ArtistDirectory";
import ArtistPage from "./pages/ArtistPage"; 

import "./styles.css"; // Make sure global styles are included
import VenuesDirectory from "./pages/VenuesDirectory";
import FansDirectory from "./pages/FansDirectory";
import CartIcon from './components/CartIcon';
import CartPage from "./pages/CartPage";


const App = () => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const client = useApolloClient();

  useEffect(() => {
    const token = sessionStorage.getItem("token");
    if (token) {
      setIsLoggedIn(true);
    }
  }, []);

  const handleLoginSuccess = () => {
    setIsLoggedIn(true);
  };

  const handleLogout = () => {
    sessionStorage.removeItem("token");
    setIsLoggedIn(false);
    client.clearStore();
  };

  return (
    <ApolloProvider client={client}>
      <Router>
        {/* ✅ Gigabout Header Appears on Every Page */}
        <div className="Gigabout">
          <header className="App-header">
            <div className="header-container">
              <div id="thename">
                <svg
                  width="100"
                  height="100"
                  viewBox="0 0 100 100"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <circle cx="50" cy="50" r="50" fill="#a83434" /> {/* Red background */}
                  <text
                    x="50%"
                    y="50%"
                    dominantBaseline="middle"
                    textAnchor="middle"
                    fontFamily="Arial"
                    fontSize="50"
                    fill="white"
                  >
                    GiG
                  </text>
                </svg>
                <h2>about Australia</h2>
                <CartIcon />
              </div>
              
            </div>
            
            
            
          </header>

          {/* Navigation Bar */}
          <nav>
            <Link to="/">Home</Link> | 
            <Link to="/merchandise"> Merchandise </Link> | 
            {isLoggedIn ? (
              <>
                <Link to="/udash"> Dashboard </Link> |  
                <button onClick={handleLogout}>Logout</button>
                

              </>
            ) : (
              <Link to="/login">Login</Link>
            )}
          </nav>

          {/* Routes for Pages */}
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/merchandise" element={<MerchandiseListPage />} />
            <Route path="/merchandise/:id" element={<PublicMerchandiseDetailPage />} />
            <Route path="/login" element={<Login onLoginSuccess={handleLoginSuccess} />} />
            <Route path="/register" element={<Register />} />
            <Route path="/udash" element={<UserDashboard />} />
            <Route path="/artistdashboard/:artistPageId" element={<ArtistDashboard />} />
            <Route path="/merchandisedashboard/:merchPageId" element={<MerchandiseDashboard />} />
            <Route path="/artist" element={<ArtistDirectory />} />
            <Route path="/artist/:id" element={<ArtistPage />} />
            <Route path="/venue" element={<VenuesDirectory />} />
            <Route path="/fan" element={<FansDirectory />}/>
            <Route path="/cart" element={<CartPage />}/>

          </Routes>
        </div>
      </Router>
    </ApolloProvider>
  );
};

export default App;
