import React from 'react';
import { useParams } from 'react-router-dom';
import { useQuery, gql } from '@apollo/client';
import ArtistPostList from '../components/ArtistPostList';
import ProfilePicture from '../components/ProfilePicture';

const GET_ARTIST_PAGE = gql`
  query GetArtistPage($input: ArtistPagesInput) {
    allArtistPages(input: $input) {
      id
      title
      description
      ownerId
      profileImage
    }
  }
`;

const ArtistPage = () => {
  const { id } = useParams();

  const { loading, error, data } = useQuery(GET_ARTIST_PAGE, {
    variables: { input: { id: parseInt(id) } },
  });

  if (loading) return <p style={{ color: 'white' }}>Loading...</p>;
  if (error) return <p style={{ color: 'white' }}>Error: {error.message}</p>;

  const artist = data.allArtistPages[0]; // The result is an array

  return (
    <div style={{ padding: '20px', color: 'white' }}>
      <ProfilePicture profileImage={artist.profileImage}/>
      <h1>{artist.title}</h1>
      <p>{artist.description || "Coming soon: artist bio, merch, posts, and more!"}</p>
      <ArtistPostList artistPageId={id} />
    </div>
  );
};

export default ArtistPage;
