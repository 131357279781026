// src/components/MerchandiseItem.js
import React, { useRef } from "react";
import { useMutation, gql } from "@apollo/client";

const UPLOAD_MERCH_IMAGE = gql`
  mutation UploadMerchImage($merchandiseItemId: ID!, $file: Upload!) {
    UploadMerchandiseImage(merchandiseItemId: $merchandiseItemId, file: $file) {
      id
      mainImage
    }
  }
`;

const MerchandiseItem = ({ item, onAddToCart }) => {
  const { id, title, description, price, mainImage, additionalImages, categoryName } = item;
  const token = sessionStorage.getItem("token");

  const fileInputRef = useRef(null);
  const [uploadImage] = useMutation(UPLOAD_MERCH_IMAGE, {
    context: {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    },
    onCompleted: (data) => {
      console.log("Image uploaded:", data.UploadMerchandiseImage.mainImage);
      window.location.reload(); // Reload to reflect image update (or use Apollo cache update)
    },
    onError: (error) => {
      console.error("Upload failed:", error.message);
    },
  });

  const handleUpload = async (event) => {
    const file = event.target.files[0];
    if (!file) return;

    await uploadImage({ variables: { merchandiseItemId: id, file } });
  };

  return (
    <div
      className="merchandise-item"
      style={{
        border: "3px solid #ccc",
        padding: "16px",
        margin: "16px 0",
        borderRadius: "8px",
        color: "white",
      }}
    >
      <h3>{title}</h3>
      {mainImage && (
        <img
          src={mainImage}
          alt={title}
          style={{
            width: "40%",
            maxHeight: "800px",
            objectFit: "contain",
            display: "block",
            margin: "0 auto",
            backgroundColor: "black",
          }}
        />
      )}

      {/* Upload/Change Image Button */}
      <button
        onClick={() => fileInputRef.current.click()}
        style={{
          display: "block",
          margin: "20px auto",
          padding: "10px 20px",
          fontSize: "16px",
          cursor: "pointer",
          backgroundColor: "#ff4500",
          color: "white",
          border: "none",
          borderRadius: "5px",
        }}
      >
        {mainImage ? "Change Image" : "Add Image"}
      </button>

      {/* Hidden File Input */}
      <input
        type="file"
        accept="image/*"
        ref={fileInputRef}
        style={{ display: "none" }}
        onChange={handleUpload}
      />

      <p>{description}</p>
      <p>
        <strong>Price:</strong> ${price}
      </p>
      <div>
        {additionalImages && additionalImages.length > 0 && (
          <>
            <h4>More images:</h4>
            <div style={{ display: "flex", gap: "8px", flexWrap: "wrap" }}>
              {additionalImages.map((img, index) => (
                <img
                  key={index}
                  src={img}
                  alt={`${title} ${index + 1}`}
                  style={{ width: "100px", height: "100px", objectFit: "cover" }}
                />
              ))}
            </div>
          </>
        )}
      </div>

      {!mainImage && <p>No image available</p>}
      <div>{categoryName}</div>
    </div>
  );
};

export default MerchandiseItem;


