// src/components/ProfilePicture.js
import React from 'react';
import './ProfilePicture.css';

const DEFAULT_IMAGE = '/assets/default-profile.jpg'; // Make sure this file exists in your public/assets folder

const ProfilePicture = ({ profileImage, size = 100 }) => {
  return (
    
    <div
      className="profile-picture"
      style={{
        width: size,
        height: size,
        borderRadius: '50%',
        overflow: 'hidden',
        border: '2px solid white',
        backgroundColor: '#444',
      }}
    >
      <img
        src={profileImage || DEFAULT_IMAGE}
        alt="Artist Profile"
        style={{
          width: '100%',
          height: '100%',
          objectFit: 'cover',
        }}
      />
      
    </div>
    
    
  );
};

export default ProfilePicture;
