import React from 'react';
import { useQuery, useMutation, gql } from '@apollo/client';
import { useNavigate, useLocation } from 'react-router-dom';
import './ArtistDashboard.css';
import ArtistPostForm from './ArtistPostForm';
import ArtistPostList from './ArtistPostList';
import ProfilePicture from './ProfilePicture';
import EditableProfilePicture from './EditableProfilePicture';


// Query to fetch the artist's merchandise page
const MY_MERCH_PAGE = gql`
  query MyMerchPage($ownerId: ID!) {
    myMerchPage(ownerId: $ownerId) {
      id
      title
      description
      ownerId
      
    }
  }
`;

const CREATE_MERCHANDISE_PAGE = gql`
  mutation CreateMerchandisePage($input: MerchandisePageInput!) {
    CreateMerchandisePage(input: $input) {
      id
      title
      description
      ownerId
    }
  }
`;

function ArtistDashboard() {
  const navigate = useNavigate();
  const { state } = useLocation();
  const title = state?.title;
  const ownerId = state?.artistPageId;
  const profileImage = state?.profileImage

  const token = sessionStorage.getItem('token');

  const { data, loading, error, refetch } = useQuery(MY_MERCH_PAGE, {
    variables: { ownerId },
    context: {
      headers: { Authorization: `Bearer ${token}` },
    },
  });

  const [createMerchPage, { loading: creating }] = useMutation(CREATE_MERCHANDISE_PAGE, {
    context: { headers: { Authorization: `Bearer ${token}` } },
    onCompleted: () => refetch(),
    onError: (err) => console.error('Error creating merchandise page:', err),
  });

  const handleCreateMerchPage = () => {
    createMerchPage({
      variables: {
        input: {
          title: title, // ✅ Automatically use artist page title
          description: `${title}'s official merchandise page`, // Or any default description
          ownerId: ownerId,
        },
      },
    });
  };

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error loading merchandise pages: {error.message}</p>;

  const merchPage = data?.myMerchPage?.[0]; // Assume one page per artist

  return (
    <div className="user-dashboard">
      <div className="merch-area">
      <EditableProfilePicture
          artistPageId={ownerId}
          imageUrl={profileImage}
          refetch={refetch}
        />
        <h1>{title}</h1>
        <h2>Your Merchandise Page</h2>

        {!merchPage ? (
          <button className="create-button" onClick={handleCreateMerchPage} disabled={creating}>
            {creating ? "Creating..." : "+ Create Merchandise Page"}
          </button>
        ) : (
          <button
            className="merch-page-button"
            onClick={() => navigate(`/merchandisedashboard/${merchPage.id}`)}
          >
            {merchPage.title}
          </button>
        )}
      </div>

      <div className="post-area">
        <h1>Artist Posts</h1>
        <ArtistPostForm artistPageId={ownerId} />
        <ArtistPostList artistPageId={ownerId} />
      </div>
    </div>
  );
}

export default ArtistDashboard;
