// src/components/CartIcon.js
// src/components/CartIcon.js
import React from 'react';
import { FaShoppingCart } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';
import { useQuery, gql } from '@apollo/client';
import './CartIcon.css';

const GET_CART = gql`
  query GetCart {
    getCart {
      items {
        id
        quantity
      }
    }
  }
`;

const CartIcon = () => {
  const navigate = useNavigate();
  const token = sessionStorage.getItem("token");

  const { data, loading, error } = useQuery(GET_CART, {
    context: {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    },
   // skip: !token,
    //fetchPolicy: 'network-only',
  });

  const itemCount = data?.getCart?.items?.reduce((sum, item) => sum + item.quantity, 0) || 0;

  return (
    <div className="cart-icon" onClick={() => navigate('/cart')} title="View Cart">
      <FaShoppingCart size={24} />
      {itemCount > 0 && <span className="cart-count">{itemCount}</span>}
    </div>
  );
};

export default CartIcon;
