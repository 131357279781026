// /pages/UserDashboard.js
import React, { useState } from 'react';
import { useQuery, useMutation, gql } from '@apollo/client';
import { useNavigate } from 'react-router-dom';
import './UserDashboard.css';

// GraphQL queries and mutations
const GET_USER_ARTIST_PAGES = gql`
  query GetUserArtistPages {
    userArtistPages {
      id
      title
      profileImage
    }
  }
`;

const CREATE_ARTIST_PAGE = gql`
  mutation CreateArtistPage($input: ArtistPageInput!) {
    CreateArtistPage(input: $input) {
      id
      title
      description
      ownerId
    }
  }
`;

function UserDashboard() {
  const navigate = useNavigate();
  const [showForm, setShowForm] = useState(false);
  const [newPage, setNewPage] = useState({ title: '', description: '' });
  const [createArtistPage] = useMutation(CREATE_ARTIST_PAGE);

  const token = sessionStorage.getItem('token');

  const { data, loading, error } = useQuery(GET_USER_ARTIST_PAGES, {
    context: {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    },
  });

  const handleArtistPageClick = (artistPageId,title, profileImage) => {
    console.log("from here: ",artistPageId)
    navigate(`/artistdashboard/${artistPageId}`, { state: { title, artistPageId, profileImage } });
  };

  

  const handleCreateArtistPage = async () => {
    try {
      const { data } = await createArtistPage({
        variables: {
          input: newPage,
        },
        context: {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
        update: (cache, { data: { CreateArtistPage } }) => {
          // Read the existing data from the cache
          const existingData = cache.readQuery({
            query: GET_USER_ARTIST_PAGES,
          });
  
          // Update the cache with the new artist page
          cache.writeQuery({
            query: GET_USER_ARTIST_PAGES,
            data: {
              userArtistPages: [
                ...existingData.userArtistPages,
                CreateArtistPage, // Add the new artist page to the list
              ],
            },
          });
        },
      });
  
      setShowForm(false); // Close the form after submission
      setNewPage({ title: '', description: '' }); // Reset form fields
    } catch (error) {
      console.error('Error creating artist page:', error);
    }
  };
  

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error loading artist pages: {error.message}</p>;

  return (
    <div className="user-dashboard">
      <h2>Your Artist Pages</h2>
      <p>Currently under devolopent. Create an artist page now and you will be able to manage in the new future.</p>
      <button className="create-button" onClick={() => setShowForm(true)}>
        + Create New Artist Page
      </button>
      {showForm && (
        <div className="create-form">
          <h3>Create New Artist Page</h3>
          <input
            type="text"
            placeholder="Title"
            value={newPage.title}
            onChange={(e) => setNewPage({ ...newPage, title: e.target.value })}
          />
          <textarea
            placeholder="Description"
            value={newPage.description}
            onChange={(e) => setNewPage({ ...newPage, description: e.target.value })}
          />
          <button onClick={handleCreateArtistPage}>Submit</button>
          <button onClick={() => setShowForm(false)}>Cancel</button>
        </div>
      )}
      <ul>
        {data.userArtistPages.map((artistPage) => (
          <li key={artistPage.id}>
            <button
              className="artist-page-button"
              onClick={() => handleArtistPageClick(artistPage.id,artistPage.title,artistPage.profileImage)}
            >
              {artistPage.title}
              
            </button>
          </li>
        ))}
      </ul>
    </div>
  );
}

export default UserDashboard;
