import React, { useEffect } from 'react';
import { useQuery, useMutation, gql } from '@apollo/client';
import { useNavigate } from 'react-router-dom';
import './CartPage.css'

const GET_CART = gql`
  query GetCart {
    getCart {
      id
      items {
        id
        quantity
        merchItem {
          id
          title
          price
          mainImage
        }
      }
    }
  }
`;

const UPDATE_CART_ITEM = gql`
  mutation Mutation($cartItemId: ID!, $quantity: Int!) {
  UpdateCartItemQuantity(cartItemId: $cartItemId, quantity: $quantity) {
    id
    quantity
    merchandiseItemId
    cartId
  }
}
`;

const REMOVE_CART_ITEM = gql`
  mutation RemoveCartItem($cartItemId: ID!) {
    RemoveCartItem(cartItemId: $cartItemId)
  }
`;


const CartPage = () => {
  const navigate = useNavigate();
  const token = sessionStorage.getItem('token');

  // ✅ Always call hooks first — even if token is missing
  const { data, loading, error, refetch } = useQuery(GET_CART, {
    context: {
      headers: { Authorization: `Bearer ${token}` },
    },
    skip: !token, // skip query if no token
    fetchPolicy: 'network-only',
  });

  const [updateItem] = useMutation(UPDATE_CART_ITEM, {
    context: { headers: { Authorization: `Bearer ${token}` } },
  });

  const [removeItem] = useMutation(REMOVE_CART_ITEM, {
    context: { headers: { Authorization: `Bearer ${token}` } },
  });

  

  // 🔁 Redirect after hooks
  useEffect(() => {
    if (!token) {
      navigate('/login');
    }
  }, [token, navigate]);

  if (!token) return null; // early exit if not logged in

  if (loading) {
    return (
      <div style={{ color: 'white', textAlign: 'center', marginTop: '80px' }}>
        <div className="spinner" />
        <p>Loading your cart...</p>
      </div>
    );
  }

  if (error) return <p style={{ color: 'white' }}>Error: {error.message}</p>;

  const cartItems = data?.getCart?.items || [];

  const handleQuantityChange = (itemId, newQty) => {
    if (newQty < 1) return;
    updateItem({ variables: { cartItemId: itemId, quantity: newQty } }).then(refetch);
  };


  const handleRemove = (itemId) => {
    removeItem({ variables: { cartItemId: itemId } }).then(refetch);
  };

  const total = cartItems.reduce((acc, item) => acc + item.quantity * item.merchItem.price, 0);

  return (
    <div className="cart-container">
      <div className="cart-items">
        {cartItems.map(({ id, quantity, merchItem }) => (
          <div className="cart-item" key={id}>
            <img src={merchItem.mainImage} alt={merchItem.title} />
            <div className="cart-details">
              <h3>{merchItem.title}</h3>
              <p>Price: ${merchItem.price}</p>
              <p>
                Quantity:
                <input
                  type="number"
                  value={quantity}
                  min="1"
                  onChange={(e) => handleQuantityChange(id, parseInt(e.target.value))}
                />
                <button className="remove-button" onClick={() => handleRemove(id)}>
                  Remove
                </button>
              </p>
            </div>
          </div>
        ))}
      </div>
  
      <div className="cart-summary">
        <h2>Order Summary</h2>
        <p>Total: <strong>${total.toFixed(2)}</strong></p>
        <button className="checkout-button">Proceed to Checkout coming soon</button>
      </div>
    </div>
  );
};

export default CartPage;
